import { i18nInstance } from "../../../../types/i18n";
import styles from "./Equipe.module.scss";

type Props = {
  i18nInstance: i18nInstance;
};

const EquipeSection = ({ i18nInstance }: Props): JSX.Element => {
  const { t } = i18nInstance;

  return (
    <section
      className={styles.section}
      style={{ backgroundImage: 'url("/img/padrao-roxo.png")' }}
      id="equipe"
    >
      <div className="container">
        <h2>{t("equipe.titulo")}</h2>

        <div className="row">
          <div className="col-md">
            <br />
            <img src="/img/ademar.png" alt="Ademar Justo" />
            <h3 className={styles.nome}>Ademar Justo</h3>
            <p>{t("equipe.ademar bio")}</p>
          </div>
          <div className="col-md">
            <br />
            <img src="/img/kelvin.png" alt="Kelvin Marques" />
            <h3 className={styles.nome}>Kelvin Marques</h3>
            <p>{t("equipe.kelvin bio")}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EquipeSection;

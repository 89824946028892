import { i18nInstance } from "../../../../types/i18n";
import styles from "./Parceiros.module.scss";

type Props = {
  i18nInstance: i18nInstance;
};

const ParceirosSection = ({ i18nInstance }: Props): JSX.Element => {
  const { t } = i18nInstance;

  return (
    <section className={styles.section} id="parceiros">
      <div className="container">
        <h2>{t("parceiros.titulo")}</h2>
        <p>{t("parceiros.p1")}</p>
        <p>{t("parceiros.p2")}</p>
      </div>
    </section>
  );
};

export default ParceirosSection;

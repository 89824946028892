import { useEffect } from "react";
import { i18nInstance } from "../../types/i18n";
import CapaSection from "./sections/Capa";
import EpicosSection from "./sections/Epicos";
import EquipeSection from "./sections/Equipe";
import NossoMotivoSection from "./sections/NossoMotivo";
import ParceirosSection from "./sections/Parceiros";
import RarosSection from "./sections/Raros";
import RegularesSection from "./sections/Regulares";
import RoadmapSection from "./sections/Roadmap";

type Props = {
  i18nInstance: i18nInstance;
  lang: string;
};

const HomePage = ({ i18nInstance, lang }: Props): JSX.Element => {
  const { i18n } = i18nInstance;

  useEffect(() => {
    setTimeout(() => i18n.changeLanguage(lang), 0);
  }, [lang]);

  return (
    <>
      <h1 style={{ position: "absolute", opacity: "0", pointerEvents: "none" }}>
        Clube do Gato Preto
      </h1>
      <CapaSection i18nInstance={i18nInstance} />
      <EpicosSection i18nInstance={i18nInstance} />
      <RarosSection i18nInstance={i18nInstance} />
      <RegularesSection i18nInstance={i18nInstance} />
      <RoadmapSection i18nInstance={i18nInstance} />
      <ParceirosSection i18nInstance={i18nInstance} />
      <NossoMotivoSection i18nInstance={i18nInstance} />
      <EquipeSection i18nInstance={i18nInstance} />
    </>
  );
};

export default HomePage;

import classNames from "classnames";
import { useMemo } from "react";
import { Gato } from "../../../../types/gato";
import { i18nInstance } from "../../../../types/i18n";
import styles from "./Epicos.module.scss";

type Props = {
  i18nInstance: i18nInstance;
};

const EpicosSection = ({ i18nInstance }: Props): JSX.Element => {
  const { t } = i18nInstance;

  const gatos: Gato[] = useMemo(() => {
    return [
      {
        tipo: 1,
        nome: t("cats.Gato Preto"),
        imagem: "/img/epicos/lendario.png",
      },
      { tipo: 2, nome: t("cats.Anjo"), imagem: "/img/epicos/anjo.png" },
      { tipo: 2, nome: t("cats.Caipira"), imagem: "/img/epicos/caipira.png" },
      { tipo: 2, nome: t("cats.Demônio"), imagem: "/img/epicos/damonio.png" },
      { tipo: 2, nome: t("cats.Gaúcho"), imagem: "/img/epicos/gaucho.png" },
      { tipo: 2, nome: t("cats.Indígena"), imagem: "/img/epicos/indigena.png" },
      {
        tipo: 2,
        nome: t("cats.Cangaceiro"),
        imagem: "/img/epicos/cangaceiro.png",
      },
      {
        tipo: 2,
        nome: t("cats.Feiticeiro"),
        imagem: "/img/epicos/feiticeiro.png",
      },
    ] as Gato[];
  }, [i18nInstance.i18n.language]);

  return (
    <section className={styles.section} id="epicos">
      <div className="container">
        <h2>{t("epicos.titulo")}</h2>
        <p>{t("epicos.p1")}</p>
        <p>{t("epicos.p2")}</p>
        <p>{t("epicos.p3")}</p>

        <div className="row justify-content-center">
          {gatos.map((gato) => (
            <div className="col-sm-6 col-md-4 col-lg-3" key={gato.nome}>
              <img src={gato.imagem} alt={gato.nome} />
              {gato.tipo === 1 ? (
                <div
                  className={classNames(styles.lendarioNome, styles.nomeStage)}
                >
                  <div>
                    <img src="/img/crown-cat-ico.svg" alt="Crown cat icon" />
                  </div>
                  <div>
                    <p className={styles.nome}>{gato.nome}</p>
                    <p className={styles.obsLendario}>{t("epicos.Landario")}</p>
                  </div>
                  <div>
                    <img src="/img/crown-cat-ico.svg" alt="Crown cat icon" />
                  </div>
                </div>
              ) : (
                <div className={styles.nomeStage}>
                  <p className={styles.nome}>{gato.nome}</p>
                  <p className={styles.obs}>{t("epicos.completo")}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default EpicosSection;

import { i18nInstance } from "../../../../types/i18n";
import styles from "./Regulares.module.scss";

type Props = {
  i18nInstance: i18nInstance;
};

const RegularesSection = ({ i18nInstance }: Props): JSX.Element => {
  const { t } = i18nInstance;

  return (
    <section className={styles.section} id="regulares">
      <div className="container">
        <h2>{t("regulares.titulo")}</h2>
        <p>{t("regulares.p1")}</p>
        <p>{t("regulares.p2")}</p>
        <p>{t("regulares.p3")}</p>
      </div>

      <div className={styles.regulares}>
        <img
          src="/img/regulares.png"
          alt="regulares"
          className={styles.regularesInner}
        />
        <img
          src="/img/regulares.png"
          alt="regulares"
          className={styles.regularesInner}
        />
      </div>
    </section>
  );
};

export default RegularesSection;

import classNames from "classnames";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { i18nInstance } from "../../types/i18n";
import { scrollToArticle } from "../../utils/scroll-to-article.utils";
import styles from "./Header.module.scss";

type Props = {
  i18nInstance: i18nInstance;
};

const HeaderComponent = ({ i18nInstance }: Props): JSX.Element => {
  const { t, i18n } = i18nInstance;

  const [menuOpen, setMenuOpen] = useState(false);
  const [languageOptionsOpen, setLanguageOptionsOpen] = useState(false);

  useEffect(() => {
    setLanguageOptionsOpen(false);
    setMenuOpen(false);
  }, [i18n.language]);

  const goToSection = useCallback((sectionName: string) => {
    setMenuOpen(false);
    scrollToArticle(sectionName);
  }, []);

  const menu = useMemo<JSX.Element>(
    () => (
      <ul>
        <li>
          <button onClick={() => goToSection("epicos")}>
            {t("menu.epicos")}
          </button>
        </li>
        <li>
          <button onClick={() => goToSection("raros-e-boleiros")}>
            {t("menu.raros e boleiros")}
          </button>
        </li>
        <li>
          <button onClick={() => goToSection("regulares")}>
            {t("menu.regulares")}
          </button>
        </li>
        <li>
          <button onClick={() => goToSection("roadmap")}>
            {t("menu.roadmap")}
          </button>
        </li>
        <li>
          <button onClick={() => goToSection("parceiros")}>
            {t("menu.parceiros")}
          </button>
        </li>
        <li>
          <button onClick={() => goToSection("nosso-motivo")}>
            {t("menu.nosso motivo")}
          </button>
        </li>
        <li>
          <button onClick={() => goToSection("equipe")}>
            {t("menu.equipe")}
          </button>
        </li>
      </ul>
    ),
    [i18n.language]
  );

  const languageButtons = useMemo(
    () => (
      <>
        {i18n.language !== "pt" && (
          <Link className={styles.btnIdioma} to="/pt">
            <img src="/img/flag-pt.png" alt="Ico PT" /> Português
          </Link>
        )}
        {i18n.language !== "en" && (
          <Link className={styles.btnIdioma} to="/en">
            <img src="/img/flag-us.png" alt="Ico PT" /> English
          </Link>
        )}
        {i18n.language !== "es" && (
          <Link className={styles.btnIdioma} to="/es">
            <img src="/img/flag-es.png" alt="Ico PT" /> Español
          </Link>
        )}
      </>
    ),
    [i18n.language]
  );

  return (
    <>
      <div className={styles.headerPlaceholder} id="headerComponent"></div>
      <header className={styles.header}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-2">
              <button className={styles.logo}>
                <img
                  src="img/logo.png"
                  alt="Black Cat Logo"
                  onClick={() => scrollToArticle("capa")}
                />
              </button>
            </div>
            <div className="col hidden-lg-down">
              <nav className={styles.menu}>{menu}</nav>
            </div>
            <div className="col-auto hidden-lg-down">
              <div className={styles.languageSwitch}>
                <button
                  className={styles.languageButton}
                  onClick={() => setLanguageOptionsOpen(!languageOptionsOpen)}
                >
                  <i className="icon-globe"></i>
                </button>
                {languageOptionsOpen && (
                  <div className={styles.languageOptions}>
                    {languageButtons}
                  </div>
                )}
              </div>
            </div>
            <div
              className={classNames(
                "col",
                "hidden-lg-up",
                styles.menuButtonContainer
              )}
            >
              <button
                className={styles.menuButton}
                onClick={() => setMenuOpen(!menuOpen)}
              >
                {menuOpen ? (
                  <i className="icon-cancel"></i>
                ) : (
                  <i className="icon-menu-outline"></i>
                )}
              </button>
            </div>
          </div>
        </div>
      </header>
      <div
        className={classNames(styles.mobileNav, {
          [styles.open]: menuOpen,
        })}
      >
        <nav>
          {menu}
          <div className={styles.languages}>{languageButtons}</div>
        </nav>
      </div>
    </>
  );
};

export default HeaderComponent;

import i18next from "i18next";
import { createRoot } from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import common_en from "./translations/en/common.json";
import common_es from "./translations/es/common.json";
import common_pt from "./translations/pt/common.json";

import "./scss/global.scss";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement as any);

i18next.init({
  interpolation: { escapeValue: false },
  resources: {
    pt: {
      common: common_pt,
    },
    en: {
      common: common_en,
    },
    es: {
      common: common_es,
    },
  },
});

root.render(
  <I18nextProvider i18n={i18next}>
    <App />
  </I18nextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

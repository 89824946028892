import { Helmet } from "react-helmet";
import { i18nInstance } from "../../types/i18n";

interface Props {
  i18nInstance: i18nInstance;
}

const MetadataComponent = ({ i18nInstance }: Props): JSX.Element => {
  const { t, i18n } = i18nInstance;

  const title = "Clube do Gato Preto";

  return (
    <Helmet>
      <html lang={i18n.language} />
      <title>{title}</title>
      <meta name="language" content={i18n.language} />
      <meta name="description" content={`${t("metadata.description")}`} />
      <meta name="keywords" content={`${t("metadata.keywords")}`} />

      <meta property="og:url" content={`www.clubedogratopreto.com.br`} />
      <meta property="og:title" content={title} />
      <meta
        property="og:description"
        content={`${t("metadata.description")}`}
      />

      <meta name="twitter:title" content={title} />
      <meta
        name="twitter:description"
        content={`${t("metadata.description")}`}
      />
    </Helmet>
  );
};

export default MetadataComponent;

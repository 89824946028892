import classNames from "classnames";
import { useCallback, useEffect, useMemo, useState } from "react";
import CatHead from "../../../../components/CatHead";
import CatHeadList from "../../../../components/CatHeadList";

import { i18nInstance } from "../../../../types/i18n";
import styles from "./Roadmap.module.scss";

type Step = {
  progress: number;
  title?: any;
  description: any[];
};

type Props = {
  i18nInstance: i18nInstance;
};

enum Progress {
  ACHIEVED = "ACHIEVED",
  CURRENT = "CURRENT",
  PENDING = "PENDING",
}

const RoadmapSection = ({ i18nInstance }: Props): JSX.Element => {
  const { t } = i18nInstance;
  const [currentProgress, setCurrentProgress] = useState(0);

  useEffect(() => {
    fetch("/progress.json")
      .then((res) => res.json())
      .then((json) => setCurrentProgress(json.progress));
  }, []);

  const steps: Step[] = useMemo(
    () => [
      {
        progress: 0,
        title: t("roadmap.0.title"),
        description: [t("roadmap.0.description")],
      },
      {
        progress: 1,
        title: t("roadmap.1.title"),
        description: [
          t("roadmap.1.description.1"),
          t("roadmap.1.description.2"),
        ],
      },
      {
        progress: 5,
        title: t("roadmap.5.title"),
        description: [t("roadmap.5.description")],
      },
      {
        progress: 10,
        description: [t("roadmap.10.description")],
      },
      {
        progress: 20,
        title: t("roadmap.20.title"),
        description: [
          t("roadmap.20.description.1"),
          t("roadmap.20.description.2"),
        ],
      },
      {
        progress: 30,
        description: [t("roadmap.30.description")],
      },
      {
        progress: 35,
        title: t("roadmap.35.title"),
        description: [t("roadmap.35.description")],
      },
      {
        progress: 40,
        description: [t("roadmap.40.description")],
      },
      {
        progress: 50,
        title: t("roadmap.50.title"),
        description: [
          t("roadmap.50.description.1"),
          t("roadmap.50.description.2"),
        ],
      },
      {
        progress: 60,
        title: t("roadmap.60.title"),
        description: [
          t("roadmap.60.description.1"),
          t("roadmap.60.description.2"),
        ],
      },
      {
        progress: 70,
        description: [t("roadmap.70.description")],
      },
      {
        progress: 80,
        title: t("roadmap.80.title"),
        description: [
          t("roadmap.80.description.1"),
          t("roadmap.80.description.2"),
        ],
      },
      {
        progress: 90,
        description: [t("roadmap.90.description")],
      },
      {
        progress: 100,
        title: t("roadmap.100.title"),
        description: [t("roadmap.100.description")],
      },
    ],
    [i18nInstance.i18n.language]
  );

  const setProgressClass = useCallback(
    (progress: number): Progress => {
      if (progress < currentProgress) {
        return Progress.ACHIEVED;
      }
      if (progress === currentProgress) {
        return Progress.CURRENT;
      }
      return Progress.PENDING;
    },
    [currentProgress]
  );

  return (
    <>
      <section className={styles.section} id="roadmap">
        <div className="container">
          <h2>{t("roadmap.titulo")}</h2>

          {steps.map((step, index) => {
            const progess = setProgressClass(step.progress);

            return (
              <div
                key={step.progress}
                className={classNames("row", styles.step, styles[progess])}
              >
                <div
                  className={classNames(
                    "col-sm-4 col-md-3 col-xl-2",
                    "offset-1",
                    styles.progressLine
                  )}
                >
                  <div
                    className={classNames(styles.bar, styles[progess], {
                      [styles.first]: index === 0,
                      [styles.last]: index === steps.length - 1,
                    })}
                  ></div>
                  <div className={styles.catHead}>
                    {progess === Progress.CURRENT && (
                      <div className={styles.currentPointer}>
                        {t("roadmap.estamos aqui")}
                      </div>
                    )}
                    <CatHead
                      color={
                        progess === Progress.PENDING ? "#FFFFFF" : "#F0F22B"
                      }
                    />
                    <span className={styles.percentage}>{step.progress}%</span>
                  </div>
                </div>
                <div className={classNames("col-sm", styles.description)}>
                  {step.title && <h4>{step.title}</h4>}
                  {step.description.map((paragraph, index) => (
                    <p key={`paragraph${index + 1}`}>{paragraph}</p>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      </section>
      <section className={styles.outrosPremios}>
        <div className="container">
          <h4>{t("roadmap.Outros prêmios")}</h4>
          <div
            className={classNames(
              "row",
              "align-items-center",
              styles.rowPremio
            )}
          >
            <div className="col-sm-3">
              <img src="/img/quadro1.png" alt="Quadro" />
            </div>
            <div className="col-sm">{t("roadmap.premios 1")}</div>
          </div>
          <div
            className={classNames(
              "row",
              "align-items-center",
              styles.rowPremio
            )}
          >
            <div className="col-sm-3">
              <img
                src="/img/quadro2.png"
                className={styles.imgPremio2}
                alt="Pôster"
              />
            </div>
            <div className="col-sm">{t("roadmap.premios 2")}</div>
          </div>
          <div
            className={classNames(
              "row",
              "align-items-center",
              styles.rowPremio
            )}
          >
            <div className="col-sm-3">
              <img
                src="/img/chaveiro.png"
                className={styles.imgPremio2}
                alt="Chaveiro"
              />
            </div>
            <div className="col-sm">{t("roadmap.premios 3")}</div>
          </div>
        </div>
      </section>

      <section className={styles.regulamento}>
        <div className="container">
          <h4>{t("Regulamento")}</h4>
          <CatHeadList
            items={[
              <>{t("roadmap.regulamento.1")}</>,
              <>{t("roadmap.regulamento.2")}</>,
              <>{t("roadmap.regulamento.3")}</>,
              <>{t("roadmap.regulamento.4")}</>,
              <>
                {t("roadmap.regulamento.5")}{" "}
                <CatHeadList
                  items={[
                    <>{t("roadmap.regulamento.5-1")}</>,
                    <>{t("roadmap.regulamento.5-2")}</>,
                  ]}
                  level={2}
                />
              </>,
            ]}
          />
        </div>
      </section>
    </>
  );
};

export default RoadmapSection;

import CatHeadList from "../../../../components/CatHeadList";
import { i18nInstance } from "../../../../types/i18n";
import { scrollToArticle } from "../../../../utils/scroll-to-article.utils";
import styles from "./NossoMotivo.module.scss";

type Props = {
  i18nInstance: i18nInstance;
};

const NossoMotivoSection = ({ i18nInstance }: Props): JSX.Element => {
  const { t, i18n } = i18nInstance;

  return (
    <section
      className={styles.section}
      style={{ backgroundImage: 'url("/img/bg-gato.jpg")' }}
      id="nosso-motivo"
    >
      <div className="container">
        <h2 className={styles.hiddenTitle}>{t("motivo.titulo")}</h2>
        <div className="row">
          <div className="col-md-10">
            <p>{t("motivo.p1")}</p>
            <p>{t("motivo.p2")}</p>
            <p>{t("motivo.p3")}</p>
            <p>
              {t("motivo.p4")}{" "}
              <a onClick={() => scrollToArticle("roadmap")}>
                {t("motivo.roadmap")}
              </a>
              .
            </p>
            <h3>{t("motivo.sub")}</h3>
            <p>{t("motivo.p5")}</p>
            {i18n.language === "pt" && (
              <CatHeadList
                items={[
                  <>{t("motivo.item1")}</>,
                  <>{t("motivo.item2")}</>,
                  <>{t("motivo.item3")}</>,
                  <>{t("motivo.item4")}</>,
                  <>{t("motivo.item5")}</>,
                  <>{t("motivo.item6")}</>,
                ]}
              />
            )}
          </div>
          <div className="col-md-2">
            <div className={styles.logo}>
              <img src="/img/logo.png" alt="Logo Clube do Gato Preto" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NossoMotivoSection;

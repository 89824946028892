import classNames from "classnames";
import { i18nInstance } from "../../../../types/i18n";
import styles from "./Capa.module.scss";

type Props = {
  i18nInstance: i18nInstance;
};

const CapaSection = ({ i18nInstance }: Props): JSX.Element => {
  const { t } = i18nInstance;

  return (
    <section className={styles.section} id="capa">
      <div className={classNames("container", styles.container)}>
        <div
          className={classNames(
            "row",
            "align-items-center",
            styles.textContainer
          )}
        >
          <div className="col-md-6">
            <video autoPlay muted loop className={styles.video}>
              <source src="/video-capa.mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="col-md-6">
            <p>{t("texto capa.p1")}</p>
            <p>{t("texto capa.p2")}</p>
            <p>{t("texto capa.p3")}</p>
            <p>{t("texto capa.p4")}</p>
          </div>
        </div>
      </div>
      <div className={styles.adopt}>
        <a
          className={styles.adoptBtn}
          href="https://opensea.io/collection/clube-do-gato-preto"
          rel="noreferrer"
          target="_blank"
        >
          <img
            className={styles.adoptImg}
            src="img/gatinho.svg"
            alt="Gatinho pidão"
          />
          {t("adote")}
        </a>
      </div>
    </section>
  );
};

export default CapaSection;

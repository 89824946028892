import { useMemo } from "react";
import { Gato } from "../../../../types/gato";
import { i18nInstance } from "../../../../types/i18n";
import styles from "./Raros.module.scss";

type Props = {
  i18nInstance: i18nInstance;
};

const RarosSection = ({ i18nInstance }: Props): JSX.Element => {
  const { t } = i18nInstance;

  const gatos: Gato[] = useMemo(() => {
    return [
      { tipo: 3, nome: t("cats.Anjo"), imagem: "/img/raros/anjo.png" },
      { tipo: 3, nome: t("cats.Caipira"), imagem: "/img/raros/caipira.png" },
      { tipo: 3, nome: t("cats.Demônio"), imagem: "/img/raros/demonio.png" },
      { tipo: 3, nome: t("cats.Gaúcho"), imagem: "/img/raros/gaucho.png" },
      { tipo: 3, nome: t("cats.Indígena"), imagem: "/img/raros/indigena.png" },
      {
        tipo: 3,
        nome: t("cats.Cangaceiro"),
        imagem: "/img/raros/cangaceiro.png",
      },
      {
        tipo: 3,
        nome: t("cats.Feiticeiro"),
        imagem: "/img/raros/feiticeiro.png",
      },
    ] as Gato[];
  }, [i18nInstance.i18n.language]);

  const boleiros: Gato[] = useMemo(() => {
    return [
      {
        tipo: 3,
        nome: t("cats.Alviverde"),
        imagem: "/img/raros/alviverde.png",
      },
      {
        tipo: 3,
        nome: t("cats.Alvinegro"),
        imagem: "/img/raros/alvinegro.png",
      },
      {
        tipo: 3,
        nome: t("cats.Rubro Negro"),
        imagem: "/img/raros/rubro-negro.png",
      },
      { tipo: 3, nome: t("cats.Tricolor"), imagem: "/img/raros/tricolor.png" },
    ] as Gato[];
  }, [i18nInstance.i18n.language]);

  return (
    <>
      <section className={styles.section} id="raros-e-boleiros">
        <div className="container">
          <h2>{t("raros.titulo")}</h2>
          <p>{t("raros.p1")}</p>
          <p>{t("raros.p2")}</p>
          <p>{t("raros.p3")}</p>

          <div className="row justify-content-center">
            {gatos.map((gato) => (
              <div className="col-sm-6 col-md-4 col-lg-3" key={gato.nome}>
                <img src={gato.imagem} alt={gato.nome} />
                <div className={styles.nomeStage}>
                  <p className={styles.nome}>{gato.nome}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className={styles.sectionBoleiros}>
        <div className="container">
          <h3>{t("raros.Boleiros")}</h3>

          <div className="row justify-content-center">
            {boleiros.map((gato) => (
              <div className="col-sm-6 col-md-4 col-lg-3" key={gato.nome}>
                <img src={gato.imagem} alt={gato.nome} />
                <div className={styles.nomeStage}>
                  <p className={styles.nome}>{gato.nome}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default RarosSection;

import parse from "html-react-parser";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import FooterComponent from "./components/Footer";
import HeaderComponent from "./components/Header";
import MetadataComponent from "./components/MetaData";
import HomePage from "./pages/Home";
import { i18nInstance } from "./types/i18n";

const App = () => {
  const { t, i18n } = useTranslation("common");

  const userLang = useMemo(
    () => (navigator.language || (navigator as any).userLanguage).split("-")[0],
    []
  );

  const i18nInstance: i18nInstance = useMemo(
    () => ({ t: (text: string) => parse(t(text)), i18n }),
    [t, i18n]
  );

  return (
    <>
      <BrowserRouter>
        <MetadataComponent i18nInstance={i18nInstance} />
        <HeaderComponent i18nInstance={i18nInstance} />
        <Routes>
          {["en", "es", "pt"].map((lang) => (
            <Route
              key={lang}
              path={`/${lang}`}
              element={<HomePage i18nInstance={i18nInstance} lang={lang} />}
            />
          ))}
          <Route path="*" element={<Navigate to={`/${userLang}`} replace />} />
        </Routes>
        <FooterComponent i18nInstance={i18nInstance} />
      </BrowserRouter>
    </>
  );
};

export default App;

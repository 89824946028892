type Props = {
  color?: string;
  strokeColor?: string;
  strokeWidth?: number;
};

const CatHead = ({ color, strokeColor, strokeWidth }: Props): JSX.Element => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 100 98.6"
    >
      <path
        fill={color || "#ffffff"}
        stroke={strokeColor || strokeWidth ? "#ffffff" : "transparent"}
        strokeWidth={strokeWidth || strokeColor ? "6" : "0"}
        d="M97.4,49.7V-0.5L74.3,29.1c-3.1-0.9-6.3-1.3-9.5-1.3H35.2c-3.2,0-6.4,0.4-9.5,1.3L2.6-0.5
       v50.2C-4.7,67.7,4,88.3,22,95.6c4.2,1.7,8.7,2.6,13.2,2.6h29.7C84.3,98.2,100,82.4,100,63C100,58.4,99.1,53.9,97.4,49.7"
      />
    </svg>
  );
};

export default CatHead;

import { i18nInstance } from "../../types/i18n";
import styles from "./Footer.module.scss";

type Props = {
  i18nInstance: i18nInstance;
};

const FooterComponent = ({ i18nInstance }: Props): JSX.Element => {
  const { t } = i18nInstance;

  return (
    <footer className={styles.footer}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-auto">{t("footer")}</div>
          <div className="col-lg">
            <ul className={styles.medias}>
              <li>
                <a
                  href="https://opensea.io/collection/clube-do-gato-preto"
                  rel="noreferrer"
                  target="_blank"
                >
                  <i className="icon-opensea"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://discord.gg/rRUjKXkM"
                  rel="noreferrer"
                  target="_blank"
                >
                  <i className="icon-discord"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/clubegatopreto"
                  rel="noreferrer"
                  target="_blank"
                >
                  <i className="icon-twitter"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/clubedogatopretoajkm"
                  rel="noreferrer"
                  target="_blank"
                >
                  <i className="icon-facebook"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/clubedogatopretoajkm"
                  rel="noreferrer"
                  target="_blank"
                >
                  <i className="icon-instagram"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterComponent;

import { useMemo } from "react";
import CatHead from "../CatHead";
import styles from "./CatHeadList.module.scss";

type Props = {
  color?: string;
  items: Array<string | JSX.Element>;
  level?: number;
};

const CatHeadList = ({ color, items, level = 1 }: Props): JSX.Element => {
  const bullet = useMemo(() => {
    switch (level) {
      case 1:
        return <CatHead color={color}></CatHead>;
      case 2:
        return (
          <CatHead
            color="transparent"
            strokeColor={color}
            strokeWidth={2}
          ></CatHead>
        );
      default:
        return <CatHead color={color}></CatHead>;
    }
  }, [level]);

  return (
    <ul className={styles.catList}>
      {items.map((item, index) => (
        <li key={`item${index}`}>
          <span>{bullet}</span>
          <span>{item}</span>
        </li>
      ))}
    </ul>
  );
};

export default CatHeadList;
